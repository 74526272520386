import { useEffect } from 'react';

interface Window {
  ZohoDeskAsapReady?: (callback?: () => void) => void;
  ZohoDeskAsap__asyncalls?: (() => void)[] | null;
  ZohoDeskAsapReadyStatus?: boolean;
}

const ZohoDeskWidget = () => {
  useEffect(() => {
    // ADICIONAR FRAME WIDGET
    const addZohoDeskScript = () => {
      if (!document.getElementById('zohodeskasapscript')) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'zohodeskasapscript';
        script.defer = true;
        script.src =
          'https://desk.zoho.com/portal/api/web/asapApp/1066918000000540084?orgId=873491422';
        document.body.appendChild(script);

        (window as Window).ZohoDeskAsapReady = function (
          callback?: () => void,
        ) {
          if (!(window as Window).ZohoDeskAsap__asyncalls) {
            (window as Window).ZohoDeskAsap__asyncalls = [];
          }

          const asyncCalls = (window as Window).ZohoDeskAsap__asyncalls!;
          if ((window as Window).ZohoDeskAsapReadyStatus) {
            if (callback) {
              asyncCalls.push(callback);
            }
            asyncCalls.forEach((cb) => cb && cb());
            (window as Window).ZohoDeskAsap__asyncalls = null;
          } else if (callback) {
            asyncCalls.push(callback);
          }
        };
      }
    };
    // REMOVER FRAME WIDGET
    const removeZohoDeskScript = () => {
      const script = document.getElementById('zohodeskasapscript');
      if (script) script.parentNode?.removeChild(script);

      const zohoIframe = document.querySelector("iframe[src*='desk.zoho.com']");
      if (zohoIframe) zohoIframe.parentNode?.removeChild(zohoIframe);
    };

    // VALIDAÇÃO PARA REMOVER WIDGET NA TELA DE PDV ONLINE
    if (window.location.pathname !== '/pdv-online') {
      addZohoDeskScript();
    } else {
      removeZohoDeskScript();
    }

    return () => {
      removeZohoDeskScript();
    };
  }, [window.location.pathname]);

  return null;
};

export default ZohoDeskWidget;
